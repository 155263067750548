import Firebase from '../Firebase'

class Analytics {
  private analytics: firebase.default.analytics.Analytics

  constructor(firebase: Firebase) {
    this.analytics = firebase.app.analytics()
  }

  logEvent(eventName = '') {
    return this.analytics.logEvent(eventName)
  }
}

export default Analytics
