import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'

export interface CheckboxProps {
  checked: boolean
  onChange?: (value: boolean) => void
  children: ReactNode
}

type FauxCheckboxProps = Pick<CheckboxProps, 'checked'>

const HiddenChecbox = styled.input`
  visibility: hidden;
  display: none;
`

const FauxCheckbox = styled.div<FauxCheckboxProps>`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grayOff};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-32px);
  &::after {
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 2px;
    transition: background 0.2s;
    background: ${({ checked, theme }) => (checked ? theme.colors.primary : theme.colors.background)};
  }
`

const StyledLabel = styled.label`
  ${({ theme }) => theme.fonts.body.mixin};
  cursor: pointer;
  font-size: 13px;
  line-height: 1.6;
  font-weight: 500;
  display: block;
  margin: 0 auto 30px;
  position: relative;
`

const Checkbox = ({ checked, onChange, children }: CheckboxProps) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => onChange?.(event.target.checked),
    [onChange],
  )

  return (
    <StyledLabel aria-checked={checked}>
      <HiddenChecbox type="checkbox" checked={checked} readOnly={true} onChange={changeHandler} />
      <FauxCheckbox checked={checked} />
      {children}
    </StyledLabel>
  )
}

export default Checkbox
