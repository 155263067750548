import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { theme } from 'src/styles'
import { makeServices, Provider as ServicesProvider } from 'src/services'
import { IntlProvider } from 'react-intl'
import { HelmetProvider } from 'react-helmet-async'
import en from 'src/i18n/locales/en.json'

const services = makeServices()

interface Props {
  children: React.ReactNode
}

const Providers = (props: Props) => {
  const { children } = props

  return (
    <ServicesProvider value={services}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <IntlProvider locale="en" defaultLocale="en" messages={en}>
            {children}
          </IntlProvider>
        </HelmetProvider>
      </ThemeProvider>
    </ServicesProvider>
  )
}

export default Providers
