import React, { Fragment } from 'react'
import { possibleScore } from 'src/services/types'
import { Row, Col } from 'react-grid-system'
import styled from 'styled-components/macro'
import T from './T'

// import { MAX_SCORE } from 'src/constants'

// type StyledNumberProps = StyledLabelProps

// const StyledNumber = styled.div<StyledNumberProps>`
//   ${({ theme }) => theme.fonts.body.mixin};
//   font-weight: bold;
//   line-height: 1;

//   padding-top: 7px;
//   font-size: 44px;
//   display: inline-block;
//   flex-grow: 0;
//   margin-right: 15px;
//   text-align: ${({ alignment = 'left' }) => alignment};
//   width: ${({ alignment = 'left' }) => (alignment === 'center' ? '100%' : 'initial')};
//   min-width: 48px;

//   @media (min-width: 767px) {
//     font-size: 48px;
//   }
// `
interface StyledLabelProps {
  alignment?: React.CSSProperties['textAlign']
}

const StyledLabel = styled.div<StyledLabelProps>`
  ${({ theme }) => theme.fonts.body.mixin};
  font-size: 16px;
  text-align: ${({ alignment = 'left' }) => alignment};
  display: inline-block;
  width: ${({ alignment = 'left' }) => (alignment === 'center' ? '100%' : 'initial')};
  min-width: 72px;
`

// const StyledScoreCol = styled(Col)`
//   display: flex;
//   align-items: center;

//   justify-content: center;
// `

// const StyledScoreWrongCol = styled(StyledScoreCol)`
//   color: ${({ theme }) => theme.colors.primary}; ;
// `

// const StyledRow = styled(Row)`
//   margin-bottom: 20px;
//   margin-top: 20px;
//   &:nth-child(1) {
//     justify-content: flex-start;
//   }

//   &:nth-child(2) {
//     justify-content: flex-end;
//   }
//   @media (min-width: 767px) {
//     padding-left: 30px;
//     padding-right: 30px;

//     margin-bottom: 40px;
//     margin-top: 40px;
//   }
// `

const StyledTitle = styled.div`
  font-size: 26px;
  line-height: 1;
  margin-bottom: 12px;
  text-align: center;
`

export interface ScoreBreakdownProps {
  correctNum: possibleScore
  wrongNum: possibleScore
  percentile: number
  className?: string
}

const ScoreBreakdown = () => {
  // const { correctNum, wrongNum } = props
  // const skippedNum = MAX_SCORE - (correctNum + wrongNum)

  // Do not show percentile info
  // If user has skipped all of the questions
  // const showPercentileInfo = skippedNum !== MAX_SCORE

  return (
    <>
      {/* {showPercentileInfo && (
        <Fragment>
          <Row justify="center">
            <Col sm={12} md={6}>
              <StyledLabel alignment="center">
                <T id="quiz_summary_page.breakdown.prefixPercentile" />
              </StyledLabel>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={6}>
              <StyledNumber alignment="center">{percentile}%</StyledNumber>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={6}>
              <StyledLabel alignment="center">
                <T id="quiz_summary_page.breakdown.sufixPercentile" />
              </StyledLabel>
            </Col>
          </Row>
        </Fragment>
      )} */}
      <Fragment>
        <Row justify="center">
          <Col sm={12}>
            <StyledTitle>
              <T id="quiz_summary_page.breakdown.congratsTitle" />
            </StyledTitle>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={12}>
            <StyledLabel alignment="center">
              <T id="quiz_summary_page.breakdown.congratsDescription" />
            </StyledLabel>
          </Col>
        </Row>
      </Fragment>

      {/* <StyledRow justify="center">
        <StyledScoreCol xs={12} sm={4}>
          <StyledNumber alignment="right">{correctNum}</StyledNumber>
          <StyledLabel>
            <T id="quiz_summary_page.breakdown.correct" values={{ correctNum, lineBreak: <br /> }} />
          </StyledLabel>
        </StyledScoreCol>
        <StyledScoreCol xs={12} sm={4}>
          <StyledNumber alignment="right">{skippedNum}</StyledNumber>
          <StyledLabel>
            <T id="quiz_summary_page.breakdown.skipped" values={{ skippedNum, lineBreak: <br /> }} />
          </StyledLabel>
        </StyledScoreCol>
        <StyledScoreWrongCol xs={12} sm={4}>
          <StyledNumber alignment="right">{wrongNum}</StyledNumber>
          <StyledLabel>
            <T id="quiz_summary_page.breakdown.wrong" values={{ wrongNum, lineBreak: <br /> }} />
          </StyledLabel>
        </StyledScoreWrongCol>
      </StyledRow> */}
    </>
  )
}

export default ScoreBreakdown
