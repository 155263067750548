import styled from 'styled-components/macro'

import { ReactComponent as LinkedinLogo } from 'src/assets/LI_logo.svg'

import { Button, ButtonProps } from 'src/components/Button'
import T from './T'

const LoginStyleButtonContainer = styled(Button)`
  position: relative;
  max-width: 290px;
  min-height: 64px;
  margin: 0 auto;
  background: ${props => props.theme.colors.primary};
  border: 1px solid transparent;
  transition: all 0.2s;
  & span {
    color: ${props => props.theme.colors.white};
    transition: all 0.2s;
  }
  & svg path {
    transition: all 0.2s;
  }
  &:hover:not(:disabled),
  &:focus {
    background: ${props => props.theme.colors.background};
    border: 1px solid ${props => props.theme.colors.primary};
    & span {
      color: ${props => props.theme.colors.primary};
    }
    & svg path {
      fill: ${props => props.theme.colors.primary};
    }
  }
`

const StyledLinkedinLogo = styled(LinkedinLogo)`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`

export const LoginWithLiButton = (props: ButtonProps) => {
  return (
    <LoginStyleButtonContainer {...props}>
      <span>
        <T id="home_page.button.login_with_linkedin" />
      </span>
      <StyledLinkedinLogo />
    </LoginStyleButtonContainer>
  )
}
