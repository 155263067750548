import { from, of, BehaviorSubject, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'

import { Quiz as QuizType } from 'shared/types'

import Firestore from './../Firestore'
import { CustomError, possibleScore } from '../types'
import last from 'ramda/es/last'

export interface ScoreResults {
  score: possibleScore
  higher: number
  lower: number
  positive: possibleScore
  negative: possibleScore
}

class Quiz {
  private firestore: Firestore
  private questions: BehaviorSubject<Array<QuizType.Question>>
  private answers: BehaviorSubject<Array<QuizType.QuestionWithAnswer>>
  private scoreResults: BehaviorSubject<ScoreResults | null>
  private totalAmountOfQuestions: BehaviorSubject<number>
  availableQuizTypes: BehaviorSubject<Array<string>>
  pickedQuizType: BehaviorSubject<string | null>

  constructor(firestore: Firestore) {
    this.firestore = firestore
    this.questions = new BehaviorSubject<Array<QuizType.Question>>([])
    this.answers = new BehaviorSubject<Array<QuizType.QuestionWithAnswer>>([])
    this.scoreResults = new BehaviorSubject<ScoreResults | null>(null)
    this.totalAmountOfQuestions = new BehaviorSubject<number>(0)
    this.availableQuizTypes = new BehaviorSubject<Array<string>>([])
    this.pickedQuizType = new BehaviorSubject<string | null>(null)
  }

  getFrontendQuiz() {
    // @TODO shared/types ENUMS not working.
    return from(this.firestore.getQuiz().where('type', '==', 'frontend').get()).pipe(
      switchMap(snapshot => {
        if (snapshot.empty) {
          throw new Error(CustomError.NoQuizFound)
        }

        return of(snapshot.docs.map(doc => doc.data() as QuizType.Data)[0])
      }),
    )
  }

  getQuestionsAnsweredAndLeftAmount() {
    return combineLatest([this.questions, this.answers, this.totalAmountOfQuestions]).pipe(
      map(results => ({
        left: results[0].length,
        answered: results[1].length,
        total: results[2],
      })),
    )
  }

  getQuestion(): QuizType.Question | null {
    if (!this.questions) {
      throw new Error()
    }

    const questions = this.questions.value
    const question = questions.pop()
    if (!question) {
      return null
    }

    this.questions.next([...questions])

    return { ...question }
  }

  getFollowingImageSneakPeek() {
    const imgUrl = last(this.questions.value)?.imageUrl
    if (imgUrl) {
      const img = new Image()
      img.src = imgUrl
    }
  }

  getAnswers(): QuizType.QuestionWithAnswer[] {
    return this.answers.getValue()
  }

  setAvailableQuizTypes(data: Array<string>) {
    return this.availableQuizTypes.next(data)
  }

  setPickedQuizType(type: string) {
    return this.pickedQuizType.next(type)
  }

  getQuizType() {
    return this.pickedQuizType.getValue()
  }

  setQuestions(data: Array<QuizType.Question>) {
    this.totalAmountOfQuestions.next(data.length)
    this.questions.next(data)

    this.getFollowingImageSneakPeek()
  }

  setAnswer(question: QuizType.Question, response: QuizType.ResponseAnswer) {
    this.answers.next([
      ...this.answers.value,
      {
        ...question,
        response,
      },
    ])
  }

  getScoreResults() {
    return this.scoreResults
  }

  setScoreResult(result: ScoreResults) {
    return this.scoreResults.next(result)
  }

  reset() {
    this.scoreResults.next(null)
    this.answers.next([])
  }
}

export default Quiz
