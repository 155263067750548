import React from 'react'
import { Col } from 'react-grid-system'

interface IDefaultColumnProps {
  children: React.ReactNode
  className?: string
}

const DefaultColumn = ({ className, children }: IDefaultColumnProps) => <Col className={className}>{children}</Col>

export default DefaultColumn
