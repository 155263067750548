import Firebase from '../Firebase'
import Auth from '../Auth'
import Quiz from '../Quiz'
import { Quiz as QuizType } from 'shared/types'
import { REGION } from 'shared/config'
import { from, ObservableInput, throwError } from 'rxjs'
import { map, tap, switchMap } from 'rxjs/operators'
import { isEmpty } from 'ramda'
import { CustomError } from '../types'

import { MAX_SCORE } from 'src/constants'

class CloudFunctions {
  private auth: Auth
  private quiz: Quiz
  private functions: firebase.default.functions.Functions

  constructor(firebase: Firebase, auth: Auth, quiz: Quiz) {
    this.functions = firebase.app.functions(REGION)
    this.auth = auth
    this.quiz = quiz
  }

  async getLinkedinUserByCode(code?: string, redirectUrl?: string) {
    const fn = this.functions.httpsCallable('getLinkedinUser')
    const { data } = await fn({ code, redirectUrl })
    if (!data || isEmpty(data)) {
      return false
    }

    this.auth.setCurrentUser(data)

    return true
  }

  getAvailableQuizTypes() {
    const quizTypes = this.functions.httpsCallable('getAvailableQuizTypes')

    return from(quizTypes()).pipe(
      map(({ data }) => data),
      tap(types => this.quiz.setAvailableQuizTypes(types)),
    )
  }

  getRandomQuizQuestions(type: string, limit = MAX_SCORE) {
    const fn = this.functions.httpsCallable('getRandomQuizQuestions')
    return from(fn({ type, limit }) as ObservableInput<{ data: Array<QuizType.Question> }>).pipe(
      map(({ data }) => data),
      tap(questions => this.quiz.setQuestions(questions)),
    )
  }

  saveAnswers() {
    const answers = this.quiz.getAnswers()
    const fn = this.functions.httpsCallable('saveAnswers')
    const pickedQuizType = this.quiz.getQuizType()
    if (!answers || answers.length === 0 || !pickedQuizType) {
      return throwError(CustomError.NoAnswers)
    }

    return from(this.auth.currentUserData).pipe(
      switchMap(user => {
        if (!user) {
          return throwError('user not logged')
        }

        return from(fn({ answers, userId: user.objectID, type: pickedQuizType })).pipe(
          tap(results => {
            this.quiz.setScoreResult(results.data)
          }),
        )
      }),
    )
  }
}

export default CloudFunctions
