import { useIntl as useReactIntl, IntlShape } from 'react-intl'

import en from 'src/i18n/locales/en.json'

export interface Values {
  [key: string]: string | number
}

export enum AvailableLocales {
  En = 'en',
}

export const locales = {
  [AvailableLocales.En]: en,
}

export type Locale = keyof typeof en
export type tFunctionType = (id: Locale, values?: Values) => string

export const useIntl = (): IntlShape & {
  t: tFunctionType
} => {
  const intl = useReactIntl()

  return {
    ...intl,
    t: (id: Locale, values?: Values) => intl.formatMessage({ id }, values),
  }
}
