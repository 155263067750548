import { useState, useEffect, useRef, useCallback } from 'react'

const DEFAULT_INTERVAL = 1000 // 1000ms === 1s

export type notifyRemainingSecondsFn = (remainingSeconds: number) => void

export const useCountdown = (
  duration: number,
  counterNumber: number,
  notifyRemainingSeconds?: notifyRemainingSecondsFn,
) => {
  const [remaining, setRemaining] = useState(duration)
  const startingTimeStamp = useRef(0)

  const handleTimeUpdate = useCallback(() => {
    const deltaSeconds = Math.floor((Date.now() - startingTimeStamp.current) / 1000)
    setRemaining(duration - deltaSeconds)
  }, [duration, startingTimeStamp])

  useEffect(() => {
    startingTimeStamp.current = Date.now()
    setRemaining(duration)
  }, [counterNumber, duration])

  useEffect(() => {
    const timerID = setInterval(handleTimeUpdate, DEFAULT_INTERVAL)
    return () => clearInterval(timerID)
  }, [handleTimeUpdate])

  useEffect(() => {
    document.addEventListener('resume', handleTimeUpdate)
    return () => document.removeEventListener('resume', handleTimeUpdate)
  }, [handleTimeUpdate])

  useEffect(() => {
    notifyRemainingSeconds?.(remaining)
  }, [remaining, notifyRemainingSeconds])

  return Math.max(remaining, 0)
}
