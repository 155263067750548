import React, { forwardRef } from 'react'
import { Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'

export const StyledButton = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme }) => theme.colors.gray};
  height: 64px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  transition: color 0.3s, border-color 0.3s;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  justify-content: space-between;
  & > span:only-child {
    margin: auto;
  }
  text-decoration: none;

  ${({ theme }) => theme.fonts.body.mixin};
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 420px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &:disabled {
    cursor: not-allowed;

    background: ${({ theme }) => theme.colors.gray};

    span {
      color: ${({ theme }) => theme.colors.grayOff};
    }
    svg path {
      fill: ${({ theme }) => theme.colors.grayOff};
    }
  }

  &:hover:not(:disabled),
  &:focus {
    @media screen and (hover: hover) {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:active:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
  }

  > span {
    color: ${({ theme }) => theme.colors.secondary};
    padding-top: 4px;
    display: inline-block;
  }
  > svg {
    margin-left: 14px;
    margin-bottom: -2px;
  }
`

const StyledLink = StyledButton.withComponent(RRDLink) as RRDLink

export interface LinkProps extends RRDLinkProps {
  text?: string
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
}

export interface AnchorProps extends React.HTMLProps<HTMLAnchorElement> {
  children: JSX.Element
}

export const Link = (props: LinkProps) => {
  const { text, children, ...restProps } = props

  return <StyledLink {...restProps}>{!!text ? <span>{text}</span> : children}</StyledLink>
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const { text, children, ...restProps } = props

  return (
    <StyledButton {...restProps} ref={forwardedRef}>
      {!!text ? <span>{text}</span> : children}
    </StyledButton>
  )
})

const AnchorRaw = (props: AnchorProps) => {
  const { children, ...restProps } = props

  return <a {...restProps}>{children}</a>
}

export const Anchor = StyledButton.withComponent(AnchorRaw)
