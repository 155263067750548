import * as React from 'react'
import { Row } from 'react-grid-system'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'

import { Quiz } from 'shared/types'

import { getShuffledArray } from 'src/utils/getShuffledArray'
import { useServices } from 'src/hooks/useServices'
import { useIntl } from 'src/hooks/useIntl'
import * as R from 'src/navigation/routes'

import { AnswerRadioButton } from 'src/components/AnswerRadioButton'
import { QuestionText } from 'src/components/QuestionText'
import DefaultColumn from 'src/components/DefaultColumn'
import Countdown from 'src/components/Countdown'
import { Button } from 'src/components/Button'
import Loader from 'src/components/Loader'
import Image from 'src/components/Image'
import T from 'src/components/T'

import { TransitioningType, StyledAnswerContainer, StyledQuestionParagraph, FadingContainer } from './utils'
import { useLoggedIn } from 'src/hooks/useIsLoggedIn'

const Sticky = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 2;
`

const ImageContainerRow = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  > span > img {
    border-radius: 16px;
    max-width: 100%;
  }
`

const StyledButtonContainer = styled.div`
  margin: 0 15px;

  > button {
    width: 226px;
    height: 64px;

    @media screen and (max-width: 613px) {
      width: 200px;
    }
    @media screen and (max-width: 512px) {
      width: 140px;
      height: 44px;
    }
  }

  @media screen and (max-width: 340px) {
    &:first-child {
      margin-right: 2px;
    }
    &:last-child {
      margin-left: 2px;
    }
  }
`

const QuizPage = () => {
  const { quiz } = useServices()

  const { t } = useIntl()

  const skipBtnRef = React.useRef<HTMLButtonElement | null>(null)

  const [redirectToHome, setRedirectToHome] = React.useState(false)
  const [redirectToSummary, setRedirectToSummary] = React.useState(false)

  const [elapsed, setElapsed] = React.useState(0)
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [question, setQuestion] = React.useState<Quiz.Question>()
  const [selectedAnswer, setSelectedAnswer] = React.useState<string>('')
  const [stepData, setStepData] = React.useState<{ left: number; answered: number; total: number }>()
  const [transitioning, setTransitioning] = React.useState<TransitioningType>('visible')

  React.useEffect(() => {
    try {
      const nextQuestion = quiz?.getQuestion()
      if (nextQuestion) {
        quiz?.getFollowingImageSneakPeek()
        setQuestion(nextQuestion)
      } else {
        setRedirectToHome(true)
      }
    } catch {
      setRedirectToHome(true)
    }

    const subscriber = quiz?.getQuestionsAnsweredAndLeftAmount().subscribe(results => {
      setStepData(results)
    })

    return () => subscriber?.unsubscribe()
  }, [quiz])

  // @TODO it could be refactored somehow or moved into utils / hook
  const handleTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      if (e.propertyName === 'transform' && e.target === e.currentTarget) {
        if (transitioning === 'fadeOut') {
          const nextQuestion = quiz?.getQuestion()
          if (nextQuestion) {
            quiz?.getFollowingImageSneakPeek()
            setQuestion(nextQuestion)
          } else {
            setQuestion(undefined)
            setRedirectToSummary(true)
          }
        }
        if (transitioning === 'visible') {
          window.scrollTo({ top: 72, behavior: 'smooth' })
        }
        // string's are coming from type ./utils.tsx::TransitioningType
        setTransitioning(current => (current === 'fadeOut' ? 'fadeIn' : 'visible'))
      }
    },
    [transitioning, quiz],
  )

  const handleGoNext = React.useCallback(
    (skipQuestion = false) => {
      if (!question || !quiz) {
        return
      }

      setTransitioning('fadeOut')
      const answer: Quiz.ResponseAnswer = {
        text: selectedAnswer,
        timeElapsed: elapsed,
        skipped: skipQuestion,
      }

      // Clear skip button focus state
      skipBtnRef.current && skipBtnRef.current.blur()

      quiz.setAnswer(question, answer)
      setSelectedAnswer('')
      setElapsed(0)
    },
    [quiz, question, elapsed, selectedAnswer],
  )

  const handleSkip = () => handleGoNext(true)
  const handleNext = () => handleGoNext(false)
  const handleTimer = React.useCallback((secondsRemaining: number) => setElapsed(60 - secondsRemaining), [])
  const shuffledAnswers = React.useMemo(() => (!question ? [] : getShuffledArray(question.answers)), [question])

  if (redirectToSummary) {
    return <Redirect to={R.QUIZ_SUMMARY} push={false} />
  }

  if (redirectToHome) {
    return <Redirect to={R.HOME} push={false} />
  }

  if (!question) {
    return (
      <h3>
        <T id="quiz_page.question_error" />
      </h3>
    )
  }

  const stepForCounter = (stepData?.answered ?? 0) + 1
  const step = stepData ? stepData.total - stepData.left : 0
  const total = stepData?.total ?? 0
  const isLastQuestion = (stepData?.left ?? 0) < 1

  const questionImage = question.imageUrl
  const imageIsLoaded = !questionImage || isImageLoaded

  return (
    <FadingContainer transitioning={transitioning} onTransitionEnd={handleTransitionEnd}>
      <Sticky>
        <Row justify="center">
          <DefaultColumn>
            <StyledQuestionParagraph>
              <T
                id="quiz_page.question_number"
                values={{
                  step,
                  total,
                }}
              />
            </StyledQuestionParagraph>
          </DefaultColumn>
        </Row>

        {imageIsLoaded && (
          <Row justify="center">
            <DefaultColumn>
              <Countdown onElapsed={handleSkip} questionNumber={stepForCounter} notifyRemainingSeconds={handleTimer} />
            </DefaultColumn>
          </Row>
        )}
      </Sticky>

      <Row justify="center">
        <DefaultColumn>
          <QuestionText>{question.text}</QuestionText>
        </DefaultColumn>
      </Row>

      {questionImage && (
        <Row justify="center">
          <DefaultColumn>
            <ImageContainerRow>
              <Image
                alt={t('quiz_page.img.alt')}
                src={questionImage}
                beforeLoad={() => setImageLoaded(false)}
                afterLoad={() => setImageLoaded(true)}
              />
            </ImageContainerRow>
          </DefaultColumn>
        </Row>
      )}

      {shuffledAnswers.map((answer, index) => (
        <Row key={index} justify="center">
          <DefaultColumn>
            <StyledAnswerContainer>
              <AnswerRadioButton
                onChange={setSelectedAnswer}
                selectedAnswer={selectedAnswer}
                answerText={answer.text}
                autoFocus={!index}
              />
            </StyledAnswerContainer>
          </DefaultColumn>
        </Row>
      ))}

      <Row justify="center">
        <DefaultColumn>
          <Row justify="between">
            <StyledButtonContainer>
              <Button onClick={handleSkip} ref={skipBtnRef}>
                <span>
                  <T id="quiz_page.button.skip" />
                </span>
              </Button>
            </StyledButtonContainer>

            <StyledButtonContainer>
              <Button onClick={handleNext} type="submit" disabled={!selectedAnswer}>
                <span> {!isLastQuestion ? <T id="quiz_page.button.next" /> : <T id="quiz_page.button.finish" />}</span>
              </Button>
            </StyledButtonContainer>
          </Row>
        </DefaultColumn>
      </Row>
    </FadingContainer>
  )
}

const QuizPageContainer = () => {
  const { isLoggedIn, isLoading } = useLoggedIn()

  if (isLoading) return <Loader />

  if (!isLoading && !isLoggedIn) return <Redirect to={R.HOME} push={false} />

  return <QuizPage />
}

export default QuizPageContainer
