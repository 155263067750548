import styled, { keyframes } from 'styled-components'

const rotateAnimation = keyframes`
   100% { transform: rotate(360deg); }
`

export const bounceAnimation = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`

const Spinner = styled.div`
  margin: 100px auto;
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  animation: ${rotateAnimation} 2s infinite ease-in-out;
`

const DefaultCube = styled.div`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  animation: ${bounceAnimation} 2s infinite ease-in-out;
`

const Cube = styled(DefaultCube)`
  top: auto;
  bottom: 0;
  animation-delay: -1s;
`

const Loader = () => (
  <Spinner>
    <DefaultCube />
    <Cube />
  </Spinner>
)

export default Loader
