import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { setConfiguration } from 'react-grid-system'

import * as R from 'src/navigation/routes'

import Providers from './providers'

import HomePage from 'src/pages/Home'
import QuizPage from 'src/pages/Quiz'
import QuizSummaryPage from 'src/pages/QuizSummary'
import NotFound from 'src/pages/NotFound'

import AppBar from 'src/components/AppBar'
import ErrorHandler from 'src/components/ErrorHandler'

// Configuration for react-grid-system.
// The container widths in pixels of devices in screen class sm, md, lg, xl, and xxl.
setConfiguration({ containerWidths: [540, 712, 712, 712, 712] })

const Navigation = () => {
  return (
    <Providers>
      <ErrorHandler>
        <BrowserRouter>
          <AppBar />
          <Switch>
            <Route exact path={R.HOME} component={HomePage} />
            <Route path={R.QUIZ} component={QuizPage} />
            <Route path={R.QUIZ_SUMMARY} component={QuizSummaryPage} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </ErrorHandler>
    </Providers>
  )
}

export default Navigation
