import { BehaviorSubject } from 'rxjs'
import { getQuizFromParams } from 'src/utils/getQuizFromParams'

import { CurrentUserModel } from './../types'

type CurrentUserType = CurrentUserModel | null

class Auth {
  LINKEDIN_STATE = `${getQuizFromParams(window.location.search)}`
  REDIRECT_URI = `${window.location.origin}/`

  currentUserData: BehaviorSubject<CurrentUserType>

  constructor() {
    this.currentUserData = new BehaviorSubject<CurrentUserType>(null)

    const user = localStorage.getItem('current_user')
    if (user !== null) {
      this.currentUserData.next(JSON.parse(user))
    }
  }

  setCurrentUser(user: CurrentUserType) {
    localStorage.setItem('current_user', JSON.stringify(user))
    this.currentUserData.next(user)
  }

  openLinkedinPopup() {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_ID}&scope=r_liteprofile%20r_emailaddress&state=${this.LINKEDIN_STATE}&redirect_uri=${this.REDIRECT_URI}`
    window.location.href = oauthUrl
  }
}

export default Auth
