import * as React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useObservable } from 'rxjs-hooks'
import { of } from 'rxjs'
import styled from 'styled-components'
// import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
// import { Link, Redirect } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import { useServices } from 'src/hooks/useServices'
import { CustomError, StateEnum } from 'src/services/types'
import { toResult } from 'src/services/utils'
// import { useIntl } from 'src/hooks/useIntl'
import * as R from 'src/navigation/routes'

// import { StyledButton, Anchor } from 'src/components/Button'
import { Anchor } from 'src/components/Button'
import DefaultColumn from 'src/components/DefaultColumn'
// import { ResultChart } from 'src/components/ResultChart'
import ScoreBreakdown from 'src/components/ScoreBreakdown'
// import Loader from 'src/components/Loader'
import T from 'src/components/T'

// import { ReactComponent as FacebookIcon } from 'src/assets/social/facebook.svg'
// import { ReactComponent as TwitterIcon } from 'src/assets/social/twitter.svg'
// import { ReactComponent as LinkedinIcon } from 'src/assets/social/linkedin.svg'
import { ReactComponent as ArrowIcon } from 'src/assets/arrow.svg'
import { useLoggedIn } from 'src/hooks/useIsLoggedIn'
import { ScoreResults } from 'src/services/Quiz'

// const StyledFacebookShareButton = StyledButton.withComponent(FacebookShareButton)
// const StyledTwitterShareButton = StyledButton.withComponent(TwitterShareButton)
// const StyledLinkedinShareButton = StyledButton.withComponent(LinkedinShareButton)

// const url = window.location.origin

const StyledContainer = styled(Container)`
  padding-top: 62px;

  h1 {
    text-align: center;
    font-size: 40px;
  }

  .brad {
    ${({ theme }) => theme.fonts.header.mixin};
  }

  .score-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .score-results-circle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      width: 226px;
      height: 226px;
      border: 4px solid red;
      border-radius: 226px;

      > h2 {
        font-size: 72px;
        margin: 0;
      }
      > h3 {
        font-size: 24px;
        margin: 0;
        text-align: center;
      }
    }
  }
  .social-media-container {
    margin-top: 30px;

    & button {
      margin: 0 0 5px;

      @media screen and (min-width: 767px) {
        display: inline-block;
        width: auto;
        margin: 0 5px;
      }
    }

    @media screen and (min-width: 767px) {
      text-align: center;
    }
  }

  .footer {
    min-height: 349px;
    margin-top: 60px;

    @media screen and (min-width: 576px) {
      margin-bottom: 95px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }

    > .left-side-text {
      padding: 30px 0;
      padding-right: 50px !important;

      > p {
        font-weight: 500;
        line-height: 30px;
      }

      > a {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        border-bottom: 2px solid ${props => props.theme.colors.primary};
      }
    }

    > .right-side-text {
      background-color: black;
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 30px 0;

      @media screen and (max-width: 767px) {
        height: 349px;
      }

      > h2 {
        margin: 0;
        font-size: 30px;
      }

      > p {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }

      > svg {
        margin: 0;
      }

      > a {
        margin: 0;
        width: 226px;
        background-color: ${props => props.theme.colors.primary};
        border: none;

        > span {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`

// const StyledTryAgainSpan = styled.span`
//   font-size: 18px;
// `

// const StyledAnchor = styled.a`
//   color: ${({ theme }) => theme.colors.primary};
// `

const CentralRow = ({ children, ...rest }: React.ComponentPropsWithoutRef<typeof Row>) => (
  <Row justify="center" {...rest}>
    {children}
  </Row>
)

// const SocialColumn = ({ children, ...rest }: React.ComponentPropsWithoutRef<typeof Col>) => (
//   <Col xs={12} {...rest}>
//     {children}
//   </Col>
// )

const HalfColumn = ({ children, ...rest }: React.ComponentPropsWithoutRef<typeof Col>) => (
  <Col xs={12} md={6} {...rest}>
    {children}
  </Col>
)

const QuizSummary = () => {
  const { cloudFunctions, quiz, analytics } = useServices()
  // const { t } = useIntl()

  const { isLoggedIn, isLoading: isLoggedInLoading } = useLoggedIn()

  const [loadFromLocalStorage, setLoadFromLocalStorage] = React.useState(false)
  const [navigateToHome, setNavigateToHome] = React.useState(false)
  const [isSavingAnswersLoading, setSavingAnswersLoading] = React.useState(true)

  const [results, setResults] = React.useState<ScoreResults | undefined>(undefined)

  const resultsFromFirebase = useObservable(() => (!quiz ? of(null) : quiz.getScoreResults()), null)

  React.useEffect(() => {
    if (!cloudFunctions || !isLoggedIn) {
      return
    }

    const subscriber = cloudFunctions
      .saveAnswers()
      .pipe(toResult())
      .subscribe(state => {
        setSavingAnswersLoading(state.status === StateEnum.Loading)

        if (state.status === StateEnum.Error) {
          if (state.payload === CustomError.NoAnswers) {
            setLoadFromLocalStorage(true)
            return
          }

          setNavigateToHome(true)
        }
      })

    return () => subscriber.unsubscribe()
  }, [cloudFunctions, isLoggedIn])

  React.useEffect(() => {
    analytics?.logEvent('summary_page')

    if (!isSavingAnswersLoading && resultsFromFirebase !== null) {
      localStorage.setItem('last-results', JSON.stringify(resultsFromFirebase))
      setResults(resultsFromFirebase)
      return
    }

    if (loadFromLocalStorage) {
      const sessionResults = localStorage.getItem('last-results')

      // Check to localstorage has info about last-results
      // If not redirect user to home screen
      return sessionResults ? setResults(JSON.parse(sessionResults)) : setNavigateToHome(true)
    }
  }, [isSavingAnswersLoading, loadFromLocalStorage, resultsFromFirebase])

  if ((!isLoggedInLoading && !isLoggedIn) || navigateToHome) {
    return <Redirect to={R.HOME} />
  }

  // const clampedScore = Math.max(results?.score ?? 0, 0)

  return (
    <StyledContainer>
      {/* <CentralRow>
        <DefaultColumn className="score-results-container">
          <h1 className="brad">
            <T id="quiz_summary_page.title" />
          </h1>
          {isSavingAnswersLoading || !results ? <Loader /> : <ResultChart score={clampedScore} />}
        </DefaultColumn>
      </CentralRow> */}
      <CentralRow>
        <Col xs={12}>
          {/* {!isSavingAnswersLoading && results && (
            <ScoreBreakdown percentile={results.lower} correctNum={results.positive} wrongNum={results.negative} />
          )} */}
          <ScoreBreakdown />
        </Col>
      </CentralRow>

      {!isSavingAnswersLoading && results ? (
        <>
          {/* <CentralRow>
            <DefaultColumn>
              <CentralRow>
                <Col width="content">
                  <StyledTryAgainSpan>
                    <T
                      id="quiz_summary_page.tryAgain"
                      values={{
                        link: text => (
                          <Link to={R.HOME} component={StyledAnchor}>
                            {text}
                          </Link>
                        ),
                      }}
                    />
                  </StyledTryAgainSpan>
                </Col>
              </CentralRow>
              <CentralRow className="social-media-container">
                <SocialColumn>
                  <StyledFacebookShareButton
                    resetButtonStyle={false}
                    url={url}
                    quote={t('quiz_summary_page.social.fb', { score: clampedScore })}
                  >
                    <span>Facebook</span>
                    <FacebookIcon />
                  </StyledFacebookShareButton>
                  <StyledTwitterShareButton
                    resetButtonStyle={false}
                    url={url}
                    title={t('quiz_summary_page.social.twitter', { score: clampedScore })}
                  >
                    <span>Twitter</span>
                    <TwitterIcon />
                  </StyledTwitterShareButton>
                  <StyledLinkedinShareButton resetButtonStyle={false} url={url}>
                    <span>Linkedin</span>
                    <LinkedinIcon />
                  </StyledLinkedinShareButton>
                </SocialColumn>
              </CentralRow>
            </DefaultColumn>
          </CentralRow> */}

          <CentralRow>
            <DefaultColumn>
              <CentralRow className="footer">
                <HalfColumn className="left-side-text">
                  <p>
                    <T id="quiz_summary_page.footer_left_side_text.p1" />
                  </p>

                  <p>
                    <T id="quiz_summary_page.footer_left_side_text.p2" />
                  </p>
                  <a href="https://withintent.com" target="_blank" rel="noreferrer">
                    <T id="quiz_summary_page.footer_left_side_text.button" />
                  </a>
                </HalfColumn>
                <HalfColumn className="right-side-text">
                  <h2 className="brad">
                    <T id="quiz_summary_page.footer_right_side_text.title" />
                  </h2>
                  <p>
                    <T id="quiz_summary_page.footer_right_side_text.text" />
                  </p>
                  <ArrowIcon />
                  <Anchor href="https://intent.recruitee.com" target="_blank">
                    <span>
                      <T id="quiz_summary_page.footer_right_side_text.button" />
                    </span>
                  </Anchor>
                </HalfColumn>
              </CentralRow>
            </DefaultColumn>
          </CentralRow>
        </>
      ) : null}
    </StyledContainer>
  )
}

export default QuizSummary
