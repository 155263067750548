import * as React from 'react'
import { Row, Container, Col } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'
import { from, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { queryToObject } from 'src/utils/queryToObject'
import { getQuizFromParams } from 'src/utils/getQuizFromParams'
import { useServices } from 'src/hooks/useServices'
import * as R from 'src/navigation/routes'

import { ReactComponent as HomeIllustration } from 'src/assets/home_illustration.svg'
import gfx from 'src/assets/GFX.svg'

import { LoginWithLiButton } from 'src/components/LoginWithLiButton'
import DefaultColumn from 'src/components/DefaultColumn'
import T from 'src/components/T'
import { Locale } from 'src/hooks/useIntl'
import Checkbox from 'src/components/Checkbox'
import { StyledButton } from 'src/components/Button'
import Loader from 'src/components/Loader'
import Dropdown from 'src/components/Dropdown'

import personalDataPdf from 'src/assets/PersonalData.pdf'
import privacyPolicyPdf from 'src/assets/PrivacyPolicy.pdf'

interface IStyledSpan {
  isExpanded: boolean
}

export interface IDropdownOption {
  value: string
}

const ReadyHeader = styled.h2`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 40px;
  ${({ theme }) => theme.fonts.header.mixin}
`

const RowWithBgGraphics = styled(Row)`
  background: center/cover no-repeat url(${gfx});
  min-height: 100%;
  padding-bottom: 200px;
`

const OlStyled = styled.ol`
  list-style: none;
  padding: 0;
  counter-reset: instructionCounter;

  @media only screen and (max-width: 564px) {
    padding: 0 20px;
  }
`

const LiStyled = styled.li`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 42px;
  margin-bottom: 32px;
  padding-left: 24px;
  width: 80%;

  counter-increment: instructionCounter;
  font-size: 13px;
  line-height: 18px;
  ${({ theme }) => theme.fonts.body.mixin}
  font-weight: 500;
  & ::before {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    content: counter(instructionCounter);
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 13px;
    line-height: 2;
    top: 8px;
    left: -15px;
  }
`

const InstructionContainer = styled.div`
  margin-top: 40px;
`

const SelectQuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 770px) {
    flex-direction: row;
    margin: 50px 0 10px 0;
  }
`

const PickerText = styled.p`
  margin: 40px 0 20px 0;
  font-size: 14px;

  @media (min-width: 770px) {
    margin: 0 30px;
  }
`

const InstructionItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Col xs={12} lg={6}>
      <LiStyled>{children}</LiStyled>
    </Col>
  )
}

const StyledGoToQuizLink = styled(StyledButton)`
  margin: 0 auto;
  max-width: 290px;
  min-height: 64px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid transparent;
  transition: background-color 0.2s, border-color 0.2s;
  > span {
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.2s;
  }
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.primary};
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    & span {
      color: ${({ theme }) => theme.colors.primary};
    }
    & svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledHomeIllustration = styled(HomeIllustration)`
  width: 100%;
`

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`

const StyledTermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledParagraph = styled.p<IStyledSpan>`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : '-webkit-box')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledReadBtn = styled.button`
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  border: 0;
  ${({ theme }) => theme.fonts.body.mixin};
  transition: opacity 0.2s;
  letter-spacing: 1.5px;
  margin: 10px 0 20px 0;

  &:hover {
    opacity: 0.5;
  }
`

function Home() {
  const { auth, cloudFunctions, quiz, analytics } = useServices()
  const history = useHistory()
  const { messages } = useIntl()

  const [isUserLoading, setUserLoading] = React.useState(false)
  const [isQuizTypesLoading, setQuizTypesLoading] = React.useState(false)
  const [isQuestionsLoading, setQuestionsLoading] = React.useState(false)
  const [pickedQuiz, setPickedQuiz] = React.useState<string | undefined>(undefined)
  const [initialQuizType, setInitialQuizType] = React.useState<string | null>(null)
  const [rodoAccepted, setRodoAccepted] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const currentUser = useObservable(() => (!auth ? of(null) : auth.currentUserData))

  React.useEffect(() => {
    if (!cloudFunctions) {
      return
    }
    setQuizTypesLoading(true)
    const subscriber = from(cloudFunctions?.getAvailableQuizTypes())
      .pipe(tap(() => setQuizTypesLoading(false)))
      .subscribe()
    return () => subscriber?.unsubscribe()
  }, [cloudFunctions])

  const quizTypes = useObservable(() => (!quiz ? of(null) : quiz.availableQuizTypes))

  React.useEffect(() => {
    const quizTypeParam = getQuizFromParams(window.location.search)
    const quizParamExist = quizTypes?.includes(quizTypeParam)

    if (quizParamExist) {
      setPickedQuiz(quizTypeParam)
      setInitialQuizType(quizTypeParam)
    }
  }, [quizTypes])

  React.useEffect(() => {
    if (currentUser) {
      setUserLoading(false)
    }
  }, [currentUser, isUserLoading, cloudFunctions])

  React.useEffect(() => {
    if (window.location.search && cloudFunctions) {
      const params = queryToObject(window.location.search)
      if (params?.state === auth?.LINKEDIN_STATE) {
        setUserLoading(true)

        const redirectRoute = params.state !== 'noType' ? `?quizType=${params.state}` : '/'

        const subscriber = from(cloudFunctions?.getLinkedinUserByCode(params.code, auth.REDIRECT_URI)).subscribe(
          isLoggedIn => {
            if (isLoggedIn) {
              window.history.replaceState({}, document.title, redirectRoute)
            }
            setUserLoading(false)
          },
        )

        return () => subscriber.unsubscribe()
      }
    }
  }, [auth, cloudFunctions])

  const handleLogin = () => {
    analytics?.logEvent('log_in_button')
    return rodoAccepted && auth?.openLinkedinPopup()
  }

  const handleGoToQuiz = () => {
    if (!cloudFunctions || !currentUser || !pickedQuiz) {
      return
    }
    setQuestionsLoading(true)
    const subscriber = from(cloudFunctions?.getRandomQuizQuestions(pickedQuiz))
      .pipe(tap(() => quiz?.reset()))
      .pipe(tap(() => quiz?.setPickedQuizType(pickedQuiz)))
      .pipe(tap(() => analytics?.logEvent('questions_loaded')))
      .pipe(tap(() => setQuestionsLoading(false)))
      .pipe(tap(() => history.push(R.QUIZ)))
      .subscribe()
    return () => subscriber?.unsubscribe()
  }

  const instructionIds = (Object.keys(messages) as Locale[]).filter(k => k.match(/^home_page\.instruction\.\d$/))
  const isCurrentUserLoaded = Boolean(currentUser)
  const quizTypesFetched = Boolean(quizTypes?.length)

  const showHeader = !isQuestionsLoading
  const showLoginView = !isCurrentUserLoaded && !isUserLoading
  const showLoader = isUserLoading || (isQuizTypesLoading && isCurrentUserLoaded) || isQuestionsLoading
  const showQuizPicker = !showLoginView && quizTypesFetched && !isQuestionsLoading
  const showInstructions = isCurrentUserLoaded && quizTypesFetched && !isQuestionsLoading

  const dropdownOptions = quizTypes?.map(type => ({ value: type }))

  const setQuiz = (quizType: IDropdownOption) => {
    setPickedQuiz(quizType.value)
  }

  return (
    <Container fluid>
      <RowWithBgGraphics justify="center">
        <DefaultColumn>
          <StyledHomeIllustration />

          {showHeader && (
            <ReadyHeader>
              <T id="home_page.ready_to_start" />
            </ReadyHeader>
          )}

          {showQuizPicker && (
            <SelectQuizContainer>
              <PickerText>
                <T id="home_page.pick_quiz" />
              </PickerText>
              <Dropdown setQuiz={setQuiz} options={dropdownOptions} initialQuizType={initialQuizType} />
            </SelectQuizContainer>
          )}

          {showLoginView && (
            <Container style={{ paddingLeft: '30px' }}>
              <Row>
                <DefaultColumn>
                  <Checkbox checked={rodoAccepted} onChange={setRodoAccepted}>
                    <h3>
                      <T
                        id="home_page.tos.heading"
                        values={{
                          link: (text: React.ReactText) => (
                            <StyledAnchor href={privacyPolicyPdf} target="_blank">
                              {text}
                            </StyledAnchor>
                          ),
                        }}
                      />
                    </h3>
                    <StyledTermsContainer>
                      <StyledParagraph isExpanded={isExpanded}>
                        <T
                          id="home_page.tos.body"
                          values={{
                            link: (text: React.ReactText) => (
                              <StyledAnchor href={personalDataPdf} target="_blank">
                                {text}
                              </StyledAnchor>
                            ),
                          }}
                        />
                      </StyledParagraph>
                      <StyledReadBtn onClick={() => setIsExpanded(!isExpanded)}>
                        <T id={`home_page.tos.${isExpanded ? 'readLessBtn' : 'readMoreBtn'}` as Locale} />
                      </StyledReadBtn>
                    </StyledTermsContainer>
                  </Checkbox>
                  <LoginWithLiButton onClick={handleLogin} disabled={!rodoAccepted} />
                </DefaultColumn>
              </Row>
            </Container>
          )}

          {showLoader && (
            <Container>
              <Row justify="center">
                <DefaultColumn>
                  <Loader />
                </DefaultColumn>
              </Row>
            </Container>
          )}

          {showInstructions && (
            <>
              <InstructionContainer>
                <OlStyled>
                  <Container>
                    <Row>
                      {instructionIds.map(id => (
                        <InstructionItem key={id}>
                          <T id={id} />
                        </InstructionItem>
                      ))}
                    </Row>
                  </Container>
                </OlStyled>
              </InstructionContainer>
              <StyledGoToQuizLink onClick={handleGoToQuiz} disabled={Boolean(!pickedQuiz)} tabIndex={0}>
                <span>
                  <T id="home_page.link.go_to_quiz" />
                </span>
              </StyledGoToQuizLink>
            </>
          )}
        </DefaultColumn>
      </RowWithBgGraphics>
    </Container>
  )
}

export default Home
