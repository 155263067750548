import { map, startWith, catchError } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { StateError, StateEnum, Loading, Success, Error } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToStateError = (err: any): StateError => {
  switch (err.code || err) {
    // for future use
    // case 'auth/too-many-requests':
    // return FirebaseError.AuthTooManyRequests
    default:
      return (err.message || err) as StateError
  }
}

export const loading = () => ({ status: StateEnum.Loading } as Loading)
export const success = <T>(payload: T) => ({ status: StateEnum.Success, payload } as Success<T>)
export const error = (payload: StateError) => ({ status: StateEnum.Error, payload } as Error)

export const toResult = <T>() => (observable: Observable<T>) =>
  observable.pipe(
    map((data: T) => success(data)),
    startWith(loading()),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchError((err: any) => {
      // eslint-disable-next-line no-console
      process.env.NODE_ENV === 'development' &&
        console.log('___ERROR [only dev, info]', err.message, err.code, err.stack)
      return of(error(mapToStateError(err)))
    }),
  )
