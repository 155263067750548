import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Locale } from 'src/hooks/useIntl'

type TValues = Record<string, React.ReactText | ((text: React.ReactText) => JSX.Element) | JSX.Element>

interface Props {
  id: Locale
  values?: TValues
}

const T = (props: Props) => {
  const { id, values } = props

  return <FormattedMessage id={id} values={values} />
}

export default T
