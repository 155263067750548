import { queryToObject } from '../queryToObject'

export const getQuizFromParams = (param: string) => {
  const params = queryToObject(param)
  if (params['state']) {
    return params.state
  }
  if (params['quizType']) {
    return params.quizType
  }
  return 'noType'
}
