import * as React from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'

import { ReactComponent as DownIcon } from 'src/assets/downIcon.svg'
import { IDropdownOption } from 'src/pages/Home/index'

interface IDropdownProps {
  setQuiz: (quizType: IDropdownOption) => void
  options: IDropdownOption[] | undefined
  initialQuizType: string | null
  onChange?: (selectedItem: string) => void
}

interface IStyled {
  isOpen: boolean
}

const StyledForm = styled.form`
  margin: 0;
  position: relative;
`

const StyledInput = styled.input<IStyled>`
  width: 190px;
  text-align: center;
  border: 0;
  padding: 13.5px 0 10px 0;
  font-weight: 600;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ isOpen }) => (isOpen ? '3px 3px 0px 0px' : '3px 3px 3px 3px')};

  ::placeholder {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledMenu = styled.ul<IStyled>`
  position: absolute;
  z-index: 10;
  border-radius: 0px 0px 3px 3px;
  cursor: default;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
`

const StyledItem = styled.li`
  list-style: none;
  padding: 13.5px 0 10px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.15s;

  &:hover {
    filter: brightness(0.92);
  }
`

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(0.9);
  }
`

const StyledSvgIcon = styled(DownIcon)`
  width: 30px;
  height: 30px;
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 1.5;
  transition: all 0.15s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }
`

const Dropdown: React.FC<IDropdownProps> = ({ options, setQuiz, initialQuizType }) => {
  return (
    <Downshift
      onChange={selectedItem => setQuiz(selectedItem as IDropdownOption)}
      itemToString={item => (item ? item.value : '')}
    >
      {({ getInputProps, getRootProps, getMenuProps, getItemProps, getToggleButtonProps, isOpen, inputValue }) => (
        <StyledForm {...getRootProps()}>
          <StyledButton {...getToggleButtonProps()}>
            <StyledSvgIcon />
          </StyledButton>
          <StyledInput
            {...getInputProps()}
            onClick={getToggleButtonProps().onClick}
            value={inputValue || initialQuizType}
            readOnly
            isOpen={isOpen}
            placeholder=" Quiz type "
          />
          <StyledMenu {...getMenuProps()} isOpen={isOpen}>
            {isOpen &&
              options?.map((item, index) => (
                <StyledItem
                  key={item.value}
                  {...getItemProps({
                    item,
                    index,
                    key: item.value,
                  })}
                >
                  {item.value}
                </StyledItem>
              ))}
          </StyledMenu>
        </StyledForm>
      )}
    </Downshift>
  )
}

export default Dropdown
