import React, { useCallback } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'src/assets/quizlogo.svg'
import { useServices } from 'src/hooks/useServices'
import * as R from 'src/navigation/routes'
import { Button } from 'src/components/Button'
import DefaultColumn from 'src/components/DefaultColumn'

import { useIntl } from 'src/hooks/useIntl'
import { useLoggedIn } from 'src/hooks/useIsLoggedIn'

const StyledBar = styled.div`
  border-bottom: 1px solid #b5b5b5;
  width: 100%;
  height: 72px;
`
const StyledLogo = styled(Logo)`
  width: 148px;
  height: 24px;
`

const LogOutButton = (props: React.ComponentProps<typeof Button>) => {
  const { auth } = useServices()
  const { t } = useIntl()

  const { isLoggedIn } = useLoggedIn()

  const handleLogout = useCallback(() => auth?.setCurrentUser(null), [auth])
  return isLoggedIn ? <Button {...props} onClick={handleLogout} text={t('global.logout')} /> : null
}

const StyledLogoRow = styled(Row)`
  height: calc(100% - 10px);
`

const StyledLogOutButton = styled(LogOutButton)`
  position: absolute;
  right: 15px;
  width: 128px;
  height: 44px;
  padding-right: 0;
  padding-left: 0;
`

const StyledLogoColumn = styled(Col)`
  @media (min-width: 920px) {
    text-align: center;
  }
`

export const AppBar = () => {
  const { t } = useIntl()
  return (
    <Container>
      <Row justify="center">
        <DefaultColumn>
          <StyledBar>
            <StyledLogoRow align="end">
              <StyledLogoColumn xs="content" sm={12}>
                <Link to={R.HOME}>
                  <StyledLogo title={t('app-bar.logo-title')} />
                </Link>
              </StyledLogoColumn>
              <StyledLogOutButton />
            </StyledLogoRow>
          </StyledBar>
        </DefaultColumn>
      </Row>
    </Container>
  )
}

export default AppBar
