import React, { Component, ErrorInfo } from 'react'
import { Col, Container, Row } from 'react-grid-system'

import T from 'src/components/T'

interface IErrorHandlerProps {
  children: React.ReactNode
}

interface IErrorHandlerState {
  hasError: boolean
}

class ErrorHandler extends Component<IErrorHandlerProps, IErrorHandlerState> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <Container fluid>
          <Row justify="center">
            <Col style={{textAlign: "center"}}>
              <h4>
                <T id="global.general_error" />
              </h4>
            </Col>
          </Row>
        </Container>
      )
    }

    return children
  }
}

export default ErrorHandler
