import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

interface ImageComponentProps {
  src: string
  alt?: string
  beforeLoad?: () => void
  afterLoad?: () => void
}

const ImageComponent = (props: ImageComponentProps) => {
  const { src, alt, beforeLoad, afterLoad } = props

  return (
    <LazyLoadImage src={src} height="auto" effect="opacity" alt={alt} beforeLoad={beforeLoad} afterLoad={afterLoad} />
  )
}

export default ImageComponent
