import styled from 'styled-components/macro'

export const QuestionText = styled.h4`
  ${props => props.theme.fonts.header.mixin};
  font-size: 40px;
  line-height: 105%;
  margin-top: 24px;
  margin-bottom: 24px;

  @media only screen and (max-width: 414px) {
    font-size: 24px;
    line-height: 30px;
  }
`
