import { pipe } from '@mobily/tifi'
import { map, sort, filter } from 'ramda'

import { Quiz } from 'shared/types'

export const getShuffledArray = (arr: Quiz.Answer[]) => {
  return pipe(
    arr,
    filter((item: Quiz.Answer) => Boolean(item.text)),
    map(item => ({
      random: Math.random(),
      item,
    })),
    sort((a, b) => a.random - b.random),
    map(obj => obj.item),
  )
}
