import Firebase from '../Firebase'

enum Collection {
  Quiz = 'quiz',
  Candidates = 'candidates',
}

class Firestore {
  private database: firebase.default.firestore.Firestore

  constructor(firebase: Firebase) {
    this.database = firebase.app.firestore()
  }

  getQuiz() {
    return this.database.collection(Collection.Quiz)
  }

  getCandidates() {
    return this.database.collection(Collection.Candidates)
  }
}

export default Firestore
