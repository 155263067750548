import React from 'react'
import styled from 'styled-components'
import { Container, Row } from 'react-grid-system'

import { Link } from 'src/components/Button'
import T from 'src/components/T'

import * as R from 'src/navigation/routes'

import gfx from 'src/assets/GFX.svg'

const RowWithBgGraphics = styled(Row)`
  background: center/cover no-repeat url(${gfx});
  min-height: calc(100vh - 73px); // Viewport height - appBar height
`
const NotFoundHeader = styled.h2`
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 40px;
  ${({ theme }) => theme.fonts.header.mixin};
`

const StyledReturnHomeLink = styled(Link)`
  margin: 0 auto;
  margin-bottom: 73px; // appBar height
  max-width: 290px;
  min-height: 64px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  > span {
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.2s;
  }
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.primary};
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    & span {
      color: ${({ theme }) => theme.colors.primary};
    }
    & svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const NotFound: React.FC<unknown> = () => {
  return (
    <Container fluid>
      <RowWithBgGraphics justify="center" align="center">
        <div>
          <NotFoundHeader>
            <T id="not_found.404" />
          </NotFoundHeader>
          <StyledReturnHomeLink to={R.HOME} tabIndex={0}>
            <span>
              <T id="not_found.return_home" />
            </span>
          </StyledReturnHomeLink>
        </div>
      </RowWithBgGraphics>
    </Container>
  )
}

export default NotFound
