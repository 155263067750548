import React, { useCallback } from 'react'
import styled from 'styled-components/macro'

export interface AnswerRadioButtonBrops {
  onChange: (value: string) => void
  selectedAnswer?: string
  answerText: string
  autoFocus: boolean
}

const StyledButtonContainer = styled.label`
  min-height: 64px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grayOff};
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  font-size: 18px;
  line-height: 22px;
  transition-property: border-color;
  transition-duration: 0.3s;
  -webkit-tap-highlight-color: transparent;
  ${({ theme }) => theme.fonts.body.mixin};
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  &:active {
    border-color: #000000;
  }
  &:focus {
    outline: none;
  }
`

const StyledInput = styled.input`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  position: relative;
  appearance: none;
  margin-right: 24px;
  border: none;
  &:focus {
    outline: none;
    &::before {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
  &::before {
    opacity: 1;
    width: 24px;
    height: 24px;
    background-color: ${props => (props.checked ? props.theme.colors.primary : 'transparent')};
    border: 1px solid ${props => (props.checked ? 'transparent' : props.theme.colors.grayOff)};
    border-radius: 50%;
    content: '';
    position: absolute;
    transition-property: background-color, border-color;
    transition-duration: 0.3s;
  }
`

const StyledTextContainer = styled.span`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 4px;
  padding-right: 10px;

  @media only screen and (max-width: 414px) {
    font-size: 14px;
    font-weight: normal;
  }
`

export const AnswerRadioButton = (props: AnswerRadioButtonBrops) => {
  const { answerText, selectedAnswer, onChange, autoFocus } = props
  const handleChange = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(value)
    },
    [onChange],
  )
  return (
    <StyledButtonContainer>
      <StyledInput
        type="radio"
        name="answer"
        value={answerText}
        checked={answerText === selectedAnswer}
        onChange={handleChange}
        autoFocus={autoFocus}
      />
      <StyledTextContainer>{answerText}</StyledTextContainer>
    </StyledButtonContainer>
  )
}
