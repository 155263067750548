import { Candidates } from 'shared/types'

export enum StateEnum {
  Loading = 1,
  Success = 2,
  Error = 3,
}

export type possibleScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export interface Success<T> {
  status: StateEnum.Success
  payload: T
}

export interface Loading {
  status: StateEnum.Loading
}

export interface Error {
  status: StateEnum.Error
  payload: StateError
}

export enum AuthError {
  UserNotLogged = 'auth/user-not-logged',
}

export enum CustomError {
  NoQuizFound = 'custom/no-quiz-found',
  NoAnswers = 'custom/no-answers',
}

export type StateError = AuthError | CustomError

export interface CurrentUserModel extends Omit<Candidates.Data, 'quizzes'> {
  objectID: string
}
