import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

import { CountdownRendererProps } from './CountdownRendererProps'
import { ReactComponent as Clock } from 'src/assets/clock.svg'

const StyledContainer = styled.div`
  height: 4px;
  width: 100%;
  background: ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.borderRadius.small};
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
`
interface StyledProgressBarProps {
  progressPercentage: number
}
const StyledProgressBar = styled.div<StyledProgressBarProps>`
  position: relative;
  height: 4px;
  width: ${props => props.progressPercentage}%;
  background: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.borderRadius.small};
  box-shadow: 0px 0px 12px #ff304d;
  transition-property: width;
  transition-duration: ${props => (props.progressPercentage > 0 ? '0.25s' : 0)};
`

const circleAnimation = keyframes`
from {transform: scale(0);}
to {
    transform: scale(1); 
}
`

const StyledCircle = styled.div`
  background: ${props => props.theme.colors.primary};
  box-shadow: 0px 0px 12px #ff304d;
  border-radius: ${props => props.theme.borderRadius.small};
  height: 16px;
  width: 16px;
  animation: 1s ${circleAnimation} cubic-bezier(0.06, 0.77, 0.7, 1.63) forwards;
  position: absolute;
  bottom: -6px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AnalogCountdownRenderer: React.FC<CountdownRendererProps> = (props: CountdownRendererProps) => {
  const { remaining, range } = props

  return (
    <StyledContainer>
      <StyledProgressBar progressPercentage={(100 * (range - remaining)) / range}>
        {range !== remaining && (
          <StyledCircle>
            <Clock />
          </StyledCircle>
        )}
      </StyledProgressBar>
    </StyledContainer>
  )
}
