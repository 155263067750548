import { DefaultTheme } from 'styled-components/macro'
export const theme: DefaultTheme = {
  colors: {
    primary: '#FF304D',
    secondary: '#000',

    background: '#E5E5E5',

    gray: '#D3D3D3',
    grayOff: '#B5B5B5',
    white: '#FFF',
  },
  borderRadius: { default: '16px', small: '8px' },
  fonts: {
    body: {
      mixin: `
        font-family: Acumin Pro Wide;
        font-weight: 600;
        font-style: normal;
      `,
    },
    header: {
      mixin: `
        font-family: Bradford LL;
        font-weight: 500;
        font-style: normal;
      `,
    },
  },
}
