import * as React from 'react'
import { useCountdown, notifyRemainingSecondsFn } from '../../hooks/useCountdown'
import { CountdownRendererProps } from './CountdownRendererProps'

export interface CountdownProps {
  durationSeconds?: number
  onElapsed?: () => void
  questionNumber: number
  notifyRemainingSeconds?: notifyRemainingSecondsFn
  component: (props: CountdownRendererProps) => JSX.Element
}

export const Countdown = (props: CountdownProps) => {
  const { durationSeconds = 60, onElapsed, component: Component, questionNumber, notifyRemainingSeconds } = props
  const time = useCountdown(durationSeconds, questionNumber, notifyRemainingSeconds)
  React.useEffect(() => {
    if (!time) {
      onElapsed?.()
    }
  }, [time, onElapsed])
  return <Component alarm={time < 10} remaining={time} range={durationSeconds} />
}
