import { useEffect, useState } from 'react'
import { useServices } from '../useServices'

export const useLoggedIn = () => {
  const { auth } = useServices()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!auth) {
      return
    }

    const subscriber = auth.currentUserData.subscribe(user => {
      setIsLoggedIn(!!user)
      setIsLoading(false)
    })

    return () => subscriber.unsubscribe()
  }, [auth])

  return {
    isLoggedIn,
    isLoading,
  }
}
