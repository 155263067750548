import { ContainerProps, Container } from 'react-grid-system'
import styled from 'styled-components/macro'

export type TransitioningType = 'visible' | 'fadeIn' | 'fadeOut'

interface FadingContainerProps extends ContainerProps {
  transitioning: TransitioningType
}

const transitionValues: { [key in TransitioningType]: string } = {
  fadeIn: '50px',
  fadeOut: '-50px',
  visible: '0',
}

const mapTransitionToTranslation = (transition: TransitioningType): string => {
  return transitionValues[transition] || '0'
}

const StyledAnswerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const StyledQuestionParagraph = styled.p`
  margin-top: 36px;
  margin-bottom: 0;
`

const FadingContainer = styled(Container)<FadingContainerProps>`
  margin-bottom: 36px;
  
  transform: translateX(${({ transitioning }) => mapTransitionToTranslation(transitioning)})};
  opacity: ${({ transitioning }) => (transitioning === 'visible' ? 1 : 0)};
 transition: opacity ${({ transitioning }) => (transitioning === 'fadeIn' ? '1ms' : '300ms')} ease-out, transform ${({
  transitioning,
}) => (transitioning === 'fadeIn' ? '1ms' : '300ms')} ease-out;`

export { StyledAnswerContainer, StyledQuestionParagraph, FadingContainer }
