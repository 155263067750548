import { createContext } from 'react'

import Auth from './Auth'
import Analytics from './Analytics'
import Firebase from './Firebase'
import Firestore from './Firestore'
import CloudFunctions from './CloudFunctions'
import Quiz from './Quiz'

class Services {
  auth: Auth
  analytics: Analytics
  quiz: Quiz
  firebase: Firebase
  firestore: Firestore
  cloudFunctions: CloudFunctions

  constructor() {
    this.auth = new Auth()
    this.firebase = new Firebase()
    this.analytics = new Analytics(this.firebase)
    this.firestore = new Firestore(this.firebase)
    this.quiz = new Quiz(this.firestore)
    this.cloudFunctions = new CloudFunctions(this.firebase, this.auth, this.quiz)
  }
}

export const Context = createContext<Partial<Services>>({})
export const Provider = Context.Provider
export const makeServices = (): Services => new Services()

export { Services }
